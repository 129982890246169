<template>
  <div>
    <div class="results align-center">
      <div class="address-bar">
        <div class="wrap align-center">
          <p>Showing deals in <strong>{{getResults.address.streetAddress}}<span v-if="getResults.address.unitNumber"></span>, {{getResults.address.unitNumber}}
          {{getResults.address.city}}, {{getResults.address.state}} {{getResults.address.zipCode}}</strong></p>
        </div>
      </div>
    </div>

    <div class="wrap" style="margin-top: 20px;">
      <h3 class="alt">Terms and Conditions</h3>
      <div class="terms panel">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rutrum massa eget tincidunt interdum. Quisque magna nulla, finibus in suscipit eu, accumsan quis nibh. Maecenas nec dolor convallis, sodales sem sed, tincidunt ante. Cras faucibus sodales velit, non tincidunt orci feugiat et. Integer sollicitudin arcu nibh, in dapibus ipsum vehicula pulvinar. Ut aliquet lorem sit amet maximus sollicitudin. Quisque ut nisl gravida, porttitor metus ac, finibus lorem. Aliquam erat volutpat. Donec in ante et magna rutrum ultricies vitae eget mauris. Ut eget dignissim lectus, id cursus nunc. Vivamus vel leo suscipit, mollis lectus id, ullamcorper ligula. Nam accumsan malesuada eleifend. Nam elit mi, accumsan quis tempor at, maximus id odio. Suspendisse nec neque pharetra enim blandit ultricies non id tortor. Nulla vitae eros rhoncus, pulvinar eros eget, lacinia nulla. Donec sollicitudin lobortis pulvinar.</p>

        <p>Mauris finibus vehicula lacinia. Aenean consectetur elit at metus ullamcorper, vel tincidunt orci imperdiet. Aenean euismod ultricies neque, vel egestas leo facilisis vel. Aliquam luctus urna est, sagittis rhoncus leo hendrerit quis. Sed eget massa vitae erat rutrum varius sed et est. Aenean elementum orci eget tristique ultricies. Donec lorem lectus, luctus ac lobortis ut, viverra mattis diam. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer non lacus eu mi condimentum mollis eu pharetra orci.</p>

        <p>Suspendisse bibendum vitae ante eu rhoncus. Sed volutpat, metus ac auctor facilisis, nibh lacus fringilla risus, cursus porttitor erat libero sed odio. Sed eu urna sed ante ultrices vehicula eu et tortor. Nulla consectetur eros in urna vestibulum malesuada. Morbi aliquam augue ut enim interdum malesuada. Nam turpis nibh, gravida quis odio convallis, sollicitudin molestie dolor. Fusce placerat augue sapien, at facilisis ipsum maximus vitae. Phasellus eu nisi tempor mi feugiat vulputate. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla vestibulum scelerisque nulla eget imperdiet. Quisque egestas pretium lacinia. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nulla placerat viverra leo in facilisis. In eu tellus at neque finibus finibus vitae quis lectus.</p>

        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rutrum massa eget tincidunt interdum. Quisque magna nulla, finibus in suscipit eu, accumsan quis nibh. Maecenas nec dolor convallis, sodales sem sed, tincidunt ante. Cras faucibus sodales velit, non tincidunt orci feugiat et. Integer sollicitudin arcu nibh, in dapibus ipsum vehicula pulvinar. Ut aliquet lorem sit amet maximus sollicitudin. Quisque ut nisl gravida, porttitor metus ac, finibus lorem. Aliquam erat volutpat. Donec in ante et magna rutrum ultricies vitae eget mauris. Ut eget dignissim lectus, id cursus nunc. Vivamus vel leo suscipit, mollis lectus id, ullamcorper ligula. Nam accumsan malesuada eleifend. Nam elit mi, accumsan quis tempor at, maximus id odio. Suspendisse nec neque pharetra enim blandit ultricies non id tortor. Nulla vitae eros rhoncus, pulvinar eros eget, lacinia nulla. Donec sollicitudin lobortis pulvinar.</p>

        <p>Mauris finibus vehicula lacinia. Aenean consectetur elit at metus ullamcorper, vel tincidunt orci imperdiet. Aenean euismod ultricies neque, vel egestas leo facilisis vel. Aliquam luctus urna est, sagittis rhoncus leo hendrerit quis. Sed eget massa vitae erat rutrum varius sed et est. Aenean elementum orci eget tristique ultricies. Donec lorem lectus, luctus ac lobortis ut, viverra mattis diam. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer non lacus eu mi condimentum mollis eu pharetra orci.</p>

        <p>Suspendisse bibendum vitae ante eu rhoncus. Sed volutpat, metus ac auctor facilisis, nibh lacus fringilla risus, cursus porttitor erat libero sed odio. Sed eu urna sed ante ultrices vehicula eu et tortor. Nulla consectetur eros in urna vestibulum malesuada. Morbi aliquam augue ut enim interdum malesuada. Nam turpis nibh, gravida quis odio convallis, sollicitudin molestie dolor. Fusce placerat augue sapien, at facilisis ipsum maximus vitae. Phasellus eu nisi tempor mi feugiat vulputate. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla vestibulum scelerisque nulla eget imperdiet. Quisque egestas pretium lacinia. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nulla placerat viverra leo in facilisis. In eu tellus at neque finibus finibus vitae quis lectus.</p>
      </div>
      <div class="wrap">
        <div class="inner sign">
          <input type="checkbox" id="acceptTerms" v-model="serviceAgreement.electronicConsentGiven">
          <label class="blue" for="acceptTerms">I agree and understand that by signing the Electronic Signature Acknowledgment and Consent Form, that all electronic signatures are the legal equivalent of my manual/handwritten signature
          </label>
        </div>
        <div class="inner sign">
          <label for="sig">
            <span>Electronic Signature</span>
            <input type="text" id="sig" v-model="serviceAgreement.signature">
          </label>
          <label for="sigdate">
            <span>Signature Date</span>
            <input type="text" id="sigdate" v-model="serviceAgreement.signatureDate">
          </label>
        </div>
      </div>
    </div>
    <div class="wrap" style="margin-top: 20px;">
      <div class="nav">
        <button type="button" v-on:click="stepFourComplete">Review and Finalize Order</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'terms',
    data () {
      return {
        serviceAgreement: {
          signature: "",
          signatureDate: "",
          electronicConsentGiven: false,
          accessibilityQuestionChecked: false,
          accessibilityQuestionField: ""
        },
      }
    },
    computed: {
      ...mapState(['resp']),
      getResults () {
        return this.$store.getters.getAvailability
      },
    },
    methods: {
      stepFourComplete () {
        this.$store.dispatch('stepFour', false)
        this.$store.dispatch('stepFive', true)
        this.$store.dispatch('saveAgreement', this.serviceAgreement)
      }
    }
  }
</script>

<style scoped lang="scss">
  * {
    box-sizing: border-box;
  }
  .wrap {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &.align-center {
      justify-content: center;
    }

    h3 {
      padding: 15px;

      &.alt {
        background-color: #34b2e3;
        color: #fff;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        font-size: 1.5rem;
        padding: 15px;
        width: 100%;
        margin: 0;
      }
    }

    .inner {
      padding: 15px;

      &.sign {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        input[type="text"] {
          margin: 0 15px 0 0;
          padding: 0;
          width: 100%;
          height: 40px;
          background-color: #E8E8E8;
          border: none;
        }

        label {
          margin-bottom: 0;
          margin-right: 15px;

          &.blue {
            color: #34B2E3;
            font-weight: 200;
            padding-left: 15px;
          }
        }
      }
    }

    h3 {
      text-align: left;
    }

    form {
      display: flex;
      flex-flow: row wrap;

      label {
        padding: 10px 0 0;
        flex: 0 0 48%;

        span {
          display: block;
        }

        input {
          width: 90%;
          height: 40px;
        }

        &.full {
          flex: 0 0 100% !important;

          span {
            display: inline-block;
          }

          input {
            width: initial;
            height: initial;
          }
        }
      }
    }

    .col {
      flex-flow: column wrap;
    }

    .panel {
      background-color: #F5F5F5;
      max-height: 500px;
      font-weight: 200;
      padding: 30px;
      overflow: scroll;
    }

    .nav {
      text-align: center;
      width: 100%;

      button {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='260.636' height='81.531' viewBox='0 0 260.636 81.531'%3E%3Cg transform='translate(-829.682 -1133.976)'%3E%3Cpath d='M595.552,1199.641H774.657l40.766,40.766-40.766,40.766H595.552l-40.766-40.766Z' transform='translate(274.895 -65.664)' fill='%23fec751'/%3E%3C/g%3E%3C/svg%3E%0A");
        color: #173962;
        transition: .3s;
        text-transform: uppercase;
        position: relative;
        font-family: 'Barlow Condensed', sans-serif;
        font-size: 24px;
        font-weight: 700;
        font-style: normal;
        min-height: 95px;
        width: 350px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 15px auto 0;
        cursor: pointer;
      }
    }
  }
</style>
