<template>
  <div class="results">
    <div v-if="getResults.isServiceAvailable">
      <div class="address-bar">
        <div class="wrap align-center">
          <p>Showing deals in <strong>{{getResults.address.streetAddress}}<span v-if="getResults.address.unitNumber"></span>, {{getResults.address.unitNumber}}
          {{getResults.address.city}}, {{getResults.address.state}} {{getResults.address.zipCode}}</strong></p>
        </div>
      </div>
      <div class="wrap align-center page-head">
        <h2>Services Available in your area</h2>
      </div>
      <div class="wrap">
        <div class="services" v-if="getInternet.isServiceOffered">
          <h2 :class="activeInternet" @click="accordInternet = !accordInternet">Internet Service<span class="svc-required" v-if="getInternet.isServiceRequired">*Service is required</span></h2>
          <div class="svc-wrap" v-if="accordInternet">
            <div class="prod-block">
              <h3>Plans</h3>
              <p class="int">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <div class="sub-block" v-for="(sp, i) in getInternet.speed" :key="i">
                <div class="inner">
                  <span v-if="sp.downSpeed" class="speed">{{ sp.downSpeed }}<br><span class="unit">{{ sp.downSpeedUnit }}</span></span>
                  <!-- <span v-if="sp.upSpeed">Upspeed: {{ sp.upSpeed }} {{ sp.upSpeedUnit }}</span> -->
                  <h4>Product Title</h4>
                  <p class="desc">This is all filler text until we decide what information ought to be put here. In the meantime, here is filler text.</p>
                  <span v-if="sp.monthlyCharge" class="price">{{ sp.monthlyCharge }} / month</span>
                  <button class="card-button" type="button" v-on:click="addInternet(true, sp.optionId)">Add Package</button>
                </div>
              </div>
              <h3>Service Requirements</h3>
              <p class="int">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <div class="sub-block" v-for="(svc, i) in getInternet.requiredFees" :key="'a' + i">
                <div class="inner">
                  <h4 v-if="svc.name">{{ svc.name }}</h4>
                  <span v-if="svc.monthlyCharge" class="price">${{ svc.monthlyCharge }} / month</span>
                  <span v-if="svc.oneTimeCharge" class="price">${{ svc.oneTimeCharge }} Setup Fee</span>
                  <span v-if="svc.price" class="price">Installation Fee: ${{ svc.price }}</span>
                </div>
              </div>
              <div class="install">
                <h3>Installation Options</h3>
                <p class="int">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <div class="sub-block" v-for="(ins, i) in getInternet.installation" :key="'b' + i">
                  <div class="inner">
                    <h4 v-if="ins.name">{{ ins.name }}</h4>
                    <span class="price" v-if="ins.price">Installation Fee: ${{ ins.price }}</span>
                    <button class="card-button" type="button" v-on:click="addInternetInstall(ins.name)">Add Installation</button>
                  </div>
                </div>
                <div class="button-wrap">
                  <button class="yellow" type="button" v-on:click="saveInternet">Complete Internet</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="services" v-if="getUtopia.isUtopiaService">
          <h2 :class="activeUtopia" @click="accordUtopia = !accordUtopia">Utopia Service</h2>
          <div class="svc-wrap" v-if="accordUtopia">
            <div class="prod-block">
              <h3>Infastructure Options</h3>
              <p class="int">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <div class="sub-block" v-for="(io, i) in getUtopia.infrastructureOptions" :key="i">
                <div class="inner">
                  <h4 v-if="io.name">{{ io.name }}</h4>
                  <p class="desc">{{ io.description }}</p>
                  <span class="price" v-if="io.monthlyCharge">${{ io.monthlyCharge }}/month</span>
                  <button type="button" class="card-button" v-on:click="addUtopia(true)">Add Package</button>
                </div>
              </div>
              <div class="button-wrap">
                <button type="button" class="yellow" v-on:click="saveUtopia">Complete Utopia</button>
              </div>
            </div>
          </div>
        </div>
        <div class="services" v-if="getTv.isServiceOffered">
          <h2 :class="activeTV" @click="accordTV = !accordTV">TV Service</h2>
          <div class="svc-wrap" v-if="accordTV">
            <div class="svc-required" v-if="getTv.isServiceRequired">*Service is required</div>
            <div class="prod-block">
              <h3>Plans</h3>
              <p class="int">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <div class="sub-block" v-for="(tv, t) in getTv.packages" :key="t">
                <div class="inner">
                  <h4 v-if="tv.name">{{ tv.name }}</h4>
                  <p class="desc">{{ tv.desc }}</p>
                  <span class="price" v-if="tv.monthlyCharge">{{ tv.monthlyCharge }}/month</span>
                  <button type="button" class="card-button" v-on:click="addTv(true, tv.packageID)">Add Package</button>
                </div>
              </div>
              <h3>Service Requirements</h3>
              <p class="int">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <div class="sub-block" v-for="(svc, i) in getTv.requiredFees" :key="'d' + i">
                <div class="inner">
                  <h4 v-if="svc.name">{{ svc.name }}</h4>
                  <span class="price" v-if="svc.monthlyCharge">${{ svc.monthlyCharge }} / month</span>
                  <span class="price" v-if="svc.oneTimeCharge">Setup Fee: ${{ svc.oneTimeCharge }}</span>
                  <span class="price" v-if="svc.price">Installation Fee: ${{ svc.price }}</span>
                </div>
              </div>
              <div class="install">
                <h3>Add-ons</h3>
                <p class="int">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <div class="sub-block" v-for="(add, i) in getTv.addons" :key="'e' + i">
                  <div class="inner">
                    <h4 v-if="add.name">{{ add.name }}</h4>
                    <span class="price" v-if="add.monthlyCharge">${{ add.monthlyCharge }}/month</span>
                    <button class="card-button" type="button" v-on:click="addTvAddons(add.optionID)">Add Package</button>
                  </div>
                </div>
              </div>
              <button type="button" class="yellow" v-on:click="saveTv">Complete TV</button>
            </div>
          </div>
        </div>
        <div class="services" v-if="getPhone.isServiceOffered">
          <h2 :class="activePhone" @click="accordPhone = !accordPhone">Phone Service<span class="svc-required" v-if="getPhone.isServiceRequired">*Service is required</span></h2>
          <div class="svc-wrap" v-if="accordPhone">
            <div class="prod-block">
              <h3>Plans</h3>
              <div class="sub-block">
                <div class="inner">
                  <h4>Phone Service</h4>
                  <p class="desc">1 line allowed. Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                  <span class="price" v-if="getPhone.monthlyChargePerLine">${{ getPhone.monthlyChargePerLine }}/month per line</span>
                  <p class="desc" v-if="getPhone.numOfLinesAllowed">Lines allowed: {{ getPhone.numOfLinesAllowed }}</p>
                  <button type="button" class="card-button" v-on:click="addPhone(true)">Add Package</button>
                </div>
              </div>
              <h3>Service Requirements</h3>
              <div class="sub-block" v-for="(ph, i) in getPhone.requiredFees" :key="'g' + i">
                <div class="inner">
                  <h4 v-if="ph.name">{{ ph.name }}</h4>
                  <span class="price" v-if="ph.monthlyCharge">${{ ph.monthlyCharge }} / month</span>
                  <span class="price" v-if="ph.oneTimeCharge">Setup Fee: ${{ ph.oneTimeCharge }}</span>
                </div>
              </div>
              <div class="install">
                <h3>Installation Options</h3>
                <p class="int">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <div class="sub-block" v-for="(ins, i) in getPhone.installation" :key="'h' + i">
                  <div class="inner">
                    <h4 v-if="ins.name">{{ ins.name }}</h4>
                    <span class="price" v-if="ins.price">Installation Fee: ${{ ins.price }}</span>
                    <p class="desc" v-if="ins.feeWaivedWithInternetInstall">Installation fee waived with Internet Install</p>
                    <button type="button" class="card-button" v-on:click="addPhoneInstall(ins.name)">Add Installation</button>
                  </div>
                </div>
              </div>
              <h3>Add-ons</h3>
              <p class="int">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <div class="sub-block" v-for="(add, i) in getPhone.addons" :key="'b' + i">
                <div class="inner">
                  <h4 v-if="add.name">{{ add.name }}</h4>
                  <span class="price" v-if="add.monthlyCharge">${{ add.monthlyCharge }}/month</span>
                  <button class="card-button" type="button" v-on:click="addPhoneAddOn(add.id)">Add Add-on</button>
                </div>
              </div>
              <div class="sub-block tf">
                <div class="inner">
                  <h4>Transfer Phone Number</h4>
                  <p class="tf-desc">Do you wish to transfer your existing phone number?</p>
                  <label :style="this.transPhone ? 'opacity: 0.6; border: 1px solid #fff' : ''" class="check" for="isTrans">
                    <input type="checkbox" id="isTrans" v-model="transPhone">Transfer
                  </label>
                  <form v-if="this.transPhone">
                    <label for="addPhNum"><span>Add Phone Number</span>
                      <input oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10" type="num" id="addPhNum" v-model="phNums">
                      <button class="card-button aphn" type="button" v-on:click="addPhoneNum(phNums)">Add Number</button>
                    </label>
                    <label for="addPhProvider"><span>Provider Name</span>
                      <input type="text" id="addPhProvider" v-model="phoneNumberTransfer.currentServiceProvider">
                    </label>
                    <label for="addPhAcct"><span>Account Number</span>
                      <input type="text" id="addPhAcct" v-model="phoneNumberTransfer.currentProviderAcctNum">
                    </label>
                    <div class="terms panel">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rutrum massa eget tincidunt interdum. Quisque magna nulla, finibus in suscipit eu, accumsan quis nibh. Maecenas nec dolor convallis, sodales sem sed, tincidunt ante. Cras faucibus sodales velit, non tincidunt orci feugiat et. Integer sollicitudin arcu nibh, in dapibus ipsum vehicula pulvinar. Ut aliquet lorem sit amet maximus sollicitudin. Quisque ut nisl gravida, porttitor metus ac, finibus lorem. Aliquam erat volutpat. Donec in ante et magna rutrum ultricies vitae eget mauris. Ut eget dignissim lectus, id cursus nunc. Vivamus vel leo suscipit, mollis lectus id, ullamcorper ligula. Nam accumsan malesuada eleifend. Nam elit mi, accumsan quis tempor at, maximus id odio. Suspendisse nec neque pharetra enim blandit ultricies non id tortor. Nulla vitae eros rhoncus, pulvinar eros eget, lacinia nulla. Donec sollicitudin lobortis pulvinar.</p>

                      <p>Mauris finibus vehicula lacinia. Aenean consectetur elit at metus ullamcorper, vel tincidunt orci imperdiet. Aenean euismod ultricies neque, vel egestas leo facilisis vel. Aliquam luctus urna est, sagittis rhoncus leo hendrerit quis. Sed eget massa vitae erat rutrum varius sed et est. Aenean elementum orci eget tristique ultricies. Donec lorem lectus, luctus ac lobortis ut, viverra mattis diam. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer non lacus eu mi condimentum mollis eu pharetra orci.</p>

                      <p>Suspendisse bibendum vitae ante eu rhoncus. Sed volutpat, metus ac auctor facilisis, nibh lacus fringilla risus, cursus porttitor erat libero sed odio. Sed eu urna sed ante ultrices vehicula eu et tortor. Nulla consectetur eros in urna vestibulum malesuada. Morbi aliquam augue ut enim interdum malesuada. Nam turpis nibh, gravida quis odio convallis, sollicitudin molestie dolor. Fusce placerat augue sapien, at facilisis ipsum maximus vitae. Phasellus eu nisi tempor mi feugiat vulputate. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla vestibulum scelerisque nulla eget imperdiet. Quisque egestas pretium lacinia. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nulla placerat viverra leo in facilisis. In eu tellus at neque finibus finibus vitae quis lectus.</p>

                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rutrum massa eget tincidunt interdum. Quisque magna nulla, finibus in suscipit eu, accumsan quis nibh. Maecenas nec dolor convallis, sodales sem sed, tincidunt ante. Cras faucibus sodales velit, non tincidunt orci feugiat et. Integer sollicitudin arcu nibh, in dapibus ipsum vehicula pulvinar. Ut aliquet lorem sit amet maximus sollicitudin. Quisque ut nisl gravida, porttitor metus ac, finibus lorem. Aliquam erat volutpat. Donec in ante et magna rutrum ultricies vitae eget mauris. Ut eget dignissim lectus, id cursus nunc. Vivamus vel leo suscipit, mollis lectus id, ullamcorper ligula. Nam accumsan malesuada eleifend. Nam elit mi, accumsan quis tempor at, maximus id odio. Suspendisse nec neque pharetra enim blandit ultricies non id tortor. Nulla vitae eros rhoncus, pulvinar eros eget, lacinia nulla. Donec sollicitudin lobortis pulvinar.</p>

                      <p>Mauris finibus vehicula lacinia. Aenean consectetur elit at metus ullamcorper, vel tincidunt orci imperdiet. Aenean euismod ultricies neque, vel egestas leo facilisis vel. Aliquam luctus urna est, sagittis rhoncus leo hendrerit quis. Sed eget massa vitae erat rutrum varius sed et est. Aenean elementum orci eget tristique ultricies. Donec lorem lectus, luctus ac lobortis ut, viverra mattis diam. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer non lacus eu mi condimentum mollis eu pharetra orci.</p>

                      <p>Suspendisse bibendum vitae ante eu rhoncus. Sed volutpat, metus ac auctor facilisis, nibh lacus fringilla risus, cursus porttitor erat libero sed odio. Sed eu urna sed ante ultrices vehicula eu et tortor. Nulla consectetur eros in urna vestibulum malesuada. Morbi aliquam augue ut enim interdum malesuada. Nam turpis nibh, gravida quis odio convallis, sollicitudin molestie dolor. Fusce placerat augue sapien, at facilisis ipsum maximus vitae. Phasellus eu nisi tempor mi feugiat vulputate. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla vestibulum scelerisque nulla eget imperdiet. Quisque egestas pretium lacinia. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nulla placerat viverra leo in facilisis. In eu tellus at neque finibus finibus vitae quis lectus.</p>
                      <label for="acceptTerms">I accept the terms and conditions for my phone number transfer request.
                        <input type="checkbox" id="acceptTerms" v-model="phoneNumberTransfer.termsAccepted">
                      </label>
                      <br>
                      <label for="sig">Electronic Signature
                        <input type="text" id="sig" v-model="phoneNumberTransfer.signature">
                      </label>
                      <br>
                      <label for="sigdate">Signature Date
                        <input type="text" id="sigdate" v-model="phoneNumberTransfer.signatureDate">
                      </label>
                    </div>
                  </form>
                </div>
                <button class="yellow" type="button" v-on:click="savePhone">Complete Phone</button>
              </div>
            </div>
          </div>
        </div>
        <div class="nav">
          <button type="button" v-on:click="stepTwoComplete">Next</button>
        </div>
      </div>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
  export default {
    name: 'getServices',
    data () {
      return {
        phNums: "",
        internetOrder: {
          isOrderingInternet: false,
          speedOptionID: "",
          installType: ""
        },
        utopiaOrder:  false,
        tv: {
          isOrderingTV: false,
          packageID: "",
          addons: []
        },
        phone: {
          isOrderingPhone: false,
          name: "",
          addons: []
        },
        bills: {
          isInit: false,
          isElectric: false,
          deliveryMethod: ''
        },
        transPhone: false,
        phoneNumberTransfer: {
          numbersToTransfer: [],
          currentServiceProvider: "",
          currentProviderAcctNum: "",
          termsAccepted: false,
          signature: "",
          signatureDate: "",
          previousPhoneBill: ""
        },
        accordInternet: false,
        accordUtopia: false,
        accordTV: false,
        accordPhone: false,
      }
    },
    computed: {
      getResults () {
        return this.$store.getters.getAvailability
      },
      getInternet () {
        return this.getResults.internetService
      },
      getTv () {
        return this.getResults.tvService
      },
      getPhone () {
        return this.getResults.phoneService
      },
      getUtopia () {
        return this.getResults.utopiaData
      },
      activeInternet () {
        return this.accordInternet ? 'open' : ''
      },
      activeUtopia () {
        return this.accordUtopia ? 'open' : ''
      },
      activeTV () {
        return this.accordTV ? 'open' : ''
      },
      activePhone () {
        return this.accordPhone ? 'open' : ''
      }
    },
    methods: {
      addInternet (isOrder, speed) {
        this.internetOrder.isOrderingInternet = isOrder
        this.internetOrder.speedOptionID = speed
      },
      addInternetInstall (type) {
        this.internetOrder.installType = type
      },
      addUtopia (added) {
        this.utopiaOrder = added
      },
      addTv (isOrder, pkg) {
        this.tv.isOrderingTV = isOrder
        this.tv.packageID = pkg
      },
      addTvAddons (addon) {
        this.tv.addons.push(addon)
      },
      addPhone (add) {
        this.phone.isOrderingPhone = add
      },
      addPhoneInstall (name) {
        this.phone.name = name
      },
      addPhoneAddOn (add) {
        this.phone.addons.push(add)
      },
      addPhoneNum (num) {
        this.phoneNumberTransfer.numbersToTransfer.push(num)
        this.phNums = ""
      },
      saveInternet () {
        this.$store.dispatch('addInternet', this.internetOrder)
      },
      saveUtopia () {
        this.$store.dispatch('addUtopia', this.utopiaOrder)
        this.$store.dispatch('addUtopiaBill', this.bills)
      },
      saveTv () {
        this.$store.dispatch('addTv', this.tv)
      },
      savePhone () {
        this.$store.dispatch('addPhone', this.phone)
        this.$store.dispatch('addPhoneTransfer', this.phoneNumberTransfer)
      },
      stepTwoComplete () {
        this.$store.dispatch('stepTwo', false)
        this.$store.dispatch('stepThree', true)
      }
    }
  }
</script>

<style scoped lang="scss">
  $dark: #093B75;
  $light: #F0F0F0;

  * {
    box-sizing: border-box;
  }

  h2,
  h3,
  h4 {
    margin: 0;
  }
  h3 {
    margin: 10px 0;
    padding: 0;
  }
  .results {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  .page-head {
    margin-top: 80px !important;
    margin-bottom: 40px !important;

    h2 {
      color: #093B75;
      text-align: center;
      font-size: 32px;
    }
  }

  .wrap {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &.align-center {
      justify-content: center;
    }

    .prod-block {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      padding: 15px;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: block;
          padding-bottom: 15px;
        }
      }

      .sub-block {
        flex: 0 0 100%;
        padding-left: 8px;
        padding-right: 8px;
        margin-bottom: 15px;

        @media screen and (min-width: 768px) {
          flex: 0 0 50%;
        }

        @media screen and (min-width: 1024px) {
          flex: 0 0 33.333%;
        }

        .inner {
          background-color: rgb(52, 178, 227);
          background-image: linear-gradient(to bottom, rgba(52, 178, 227, 1) 0%, rgba(9, 59, 117, 1) 100%);
          padding: 30px 0 15px;
          min-height: 280px;
        }

        span,
        label {
          display: block;
          padding: 3px 0;

          &.icons {
            width: 12px;
          }

          &.speed {
            border: 1px solid #FEC751;
            text-align: center;
            margin: 0 auto;
            width: 150px;
            height: 150px;
            border-radius: 50%;
            letter-spacing: 1.25px;
            font-weight: 700;
            color: #fff;
            font-size: 60px;
            line-height: 0.95;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .unit {
              font-size: 36px;
            }
          }

          &.price {
            display: block;
            color: rgba(255, 255, 255, 0.8);
            text-align: center;
            font-size: 32px;
            letter-spacing: 1.5px;
            margin-bottom: 30px;
          }
        }

        h4 {
          text-align: center;
          color: rgba(255, 255, 255, 0.8);
          font-size: 36px;
          letter-spacing: 1.25px;
          margin: 15px 0;
        }

        .desc {
          max-width: 80%;
          margin: 0 auto 30px;
          color: #fff;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0.5px;
          text-align: center;
        }

        &.sub {
          border-bottom: none;
          margin-bottom: 0;
        }

        .terms {
          padding: 15px;

          &.panel {
            background-color: #fff;
            max-height: 350px;
            overflow: scroll;
            margin: 20px;

            p {
              font-weight: 300;
            }
          }
        }

        &.tf {
          flex: 0 0 100%;

          @media screen and (min-width: 1024px) {
            flex: 0 0 66.666%
          }

          .inner {
            background-color: #093B75;
            background-image: none;
            padding: 30px 0 15px;
            min-height: 280px;
          }

          h4 {
            color: #fff;
          }

          .tf-desc {
            color: rgba(255, 255, 255, 0.8);
            text-align: center;
            font-size: 24px;
            font-weight: 300;
          }

          label {
            display: block;
            margin: 0 auto;
            max-width: 450px;
            position: relative;

            &.check {
              text-align: center;
              background-image: none;
              background-color: #34B2E3;
              color: #fff;
              font-weight: 400;
              font-size: 16px;
              letter-spacing: 1px;
              text-transform: none;
              border-radius: 5px;
              width: 100%;
              max-width: 140px;
              padding: 13px 0;
              margin: 15px auto;
              display: block;
              cursor: pointer;

              input[type="checkbox"] {
                position: absolute;
                opacity: 0;
              }

              &.active {
                opacity: 0.8;
              }
            }

            span {
              display: block;
              color: #fff;
              font-size: 18px;
            }

            .aphn {
              position: absolute;
              top: 15px;
              right: -5px;
              min-height: 42px;
            }
          }

          input[type="text"],
          input[type="num"] {
            width: 100%;
            padding: 10px;
            font-size: 18px;
            background-color: $light;
            border: none;
            border-radius: 4px;
          }
        }
      }



      h3 {
        width: 100%;
        color: #093B75;
        font-size: 50px;
        font-weight: 400;
        text-align: center;
        margin: 30px 0;
      }

      .int {
        color: #093B75;
        text-align: center;
        width: 100%;
        margin: 0 0 50px
      }

      .install {
        background-color: #EAEAEA;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
        padding: 30px;
        margin-top: 30px;

        .sub-block {
          .inner {
            background-color: #34B2E3;
            background-image: none;
          }
        }

        .card-button {
          background-color: #fff;
          color: #34B2E3;
        }
      }
    }

    .prod-name {
      display: block;
      font-weight: bold;
      font-size: 16px;
    }

    .monthly,
    .one-time {
      display: block;
      font-weight: normal;
      font-size: 14px;
    }
  }

  .services { 
    width: 100%;
    margin-bottom: 20px;

    h2 {
      background-color: #34B2E3;
      color: #fff;
      font-weight: 700;
      padding: 20px 15px;
      border-radius: 4px;
      text-transform: uppercase;
      display: flex;
      cursor: pointer;

      &::before {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='51.148' height='26.912' viewBox='0 0 51.148 26.912'%3E%3Cpath id='Path_15' data-name='Path 15' d='M2316.375,57.438l21.892,18.688,20.79-18.687' transform='translate(-2312.146 -53.201)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='6'/%3E%3C/svg%3E%0A");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        display: block;
        width: 30px;
        margin-right: 40px;
        transform-origin: center;
        transform: rotate(-90deg);
        transition: transform 0.3s ease-in-out;
      }

      &.open {
        &::before {
          transform: rotate(0);
        }
      }
    }

    .svc-required {
      font-size: 16px;
      font-weight: 300;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      letter-spacing: 0.25px;
      margin-left: auto;
      margin-top: 5px;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      &.info-block {
        display: flex;
        flex-flow: row wrap;

        li {
          margin-right: 30px;
          padding: 0;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .svc-wrap {
    background-color: #F4F4F4;
  }

  .nav {
    text-align: center;
    width: 100%;
    margin: 15px auto 30px;
  }

  .button-wrap {
    flex: 0 0 100%;
    max-width: 100%;
  }

  button {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='260.636' height='81.531' viewBox='0 0 260.636 81.531'%3E%3Cg transform='translate(-829.682 -1133.976)'%3E%3Cpath d='M595.552,1199.641H774.657l40.766,40.766-40.766,40.766H595.552l-40.766-40.766Z' transform='translate(274.895 -65.664)' fill='%23fec751'/%3E%3C/g%3E%3C/svg%3E%0A");
    color: #173962;
    transition: .3s;
    text-transform: uppercase;
    position: relative;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    min-height: 45px;
    width: 180px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 15px auto 0;
    cursor: pointer;

    &.card-button {
      background-image: none;
      background-color: #34B2E3;
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 1px;
      text-transform: none;
      border-radius: 5px;
      width: 100%;
      max-width: 140px;
      margin: 15px auto;
      display: block;
    }

    &.yellow {
     background-image: none;
     background-color: #FEC751;
     color: #093B75;
     font-weight: 700;
     font-size: 24px;
     letter-spacing: 1px;
     text-transform: uppercase;
     border-radius: 5px;
     width: 100%;
     max-width: 250px;
     margin: 15px auto;
     display: block;
   }
 }

 .icon-wrap {
  display: flex !important;
}
</style>
