<template>
  <div class="home">
    <section class="hero">
      <h1>JOIN THE HIVE</h1>
      <h2>Buzz into Fast, Reliable Internet</h2>
      <p>Whether looking for fast, reliable, fiber-optic internet for home or work, Beehive Broadband provides exceptional service to accommodate your needs.</p>
    </section>
    <getAvailability v-if="sOne" />
    <getServices v-if="sTwo" />
    <customerInformation v-if="sThree" />
    <terms v-if="sFour" />
    <!-- TODO: Create Step 5 Component: Review and Submit Order. Pull entire order and display, create edit buttons to go back and make changes. -->
    <review v-if="sFive" />
  </div>
</template>

<script>
// @ is an alias to /src
import getAvailability from '@/components/getAvailability.vue'
import getServices from '@/components/getServices.vue'
import customerInformation from '@/components/customerInformation.vue'
import terms from '@/components/terms.vue'
import review from '@/components/review.vue'

export default {
  name: 'Home',
  components: {
    getAvailability,
    getServices,
    customerInformation,
    terms,
    review
  },
  computed: {
    sOne () {
      return this.$store.getters.getStepOne
    },
    sTwo () {
      return this.$store.getters.getStepTwo
    },
    sThree () {
      return this.$store.getters.getStepThree
    },
    sFour () {
      return this.$store.getters.getStepFour
    },
    sFive () {
      return this.$store.getters.getStepFive
    }
  },
  mounted () {
    this.$store.dispatch('formInit')
  }
}
</script>
<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300;500;700&display=swap');

  $dark: #093B75;

  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Barlow Condensed', sans-serif;
    margin: 0;
    padding: 0;
  }

  .hero {
    background-image: url('../assets/images/hero.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 80px 0;

    h1 {
      font-size: 54px;
      font-weight: 700;
      color: #fff;
      margin: 0 0 30px;
      padding: 0;
      text-align: center;

      @media screen and (min-width: 1024px) {
        font-size: 80px;
      }
    }

    h2 {
      font-size: 38px;
      font-weight: 700;
      color: $dark;
      margin: 0 0 30px;
      padding: 0;
      text-align: center;

      @media screen and (min-width: 1024px) {
        font-size: 50px;
      }
    }

    p {
      font-size: 18px;
      font-weight: 500;
      color: $dark;
      margin: 0 auto;
      padding: 0;
      text-align: center;
      max-width: 800px;

      @media screen and (min-width: 1024px) {
        font-size: 24px;
      }
    }
  }

  h1,
  h2,
  h3 {
    font-family: 'Barlow Condensed', sans-serif;
    line-height: 1;
  }

  .address-bar {
    background-color: rgba(52, 178, 227, 0.15);

    p {
      color: #093B75;
      font-weight: 300;
      font-size: 18px;
    }
  }
</style>
