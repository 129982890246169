<template>
  <div>
    <div class="wrap align-center">
      <h2>Finalize Your Order</h2>
    </div>
    <div class="results wrap align-center">
      <div class="inner">
        <ul class="centered">
          <li v-if="getOrderCD.firstName"><span v-if="getOrderCD.firstName">{{ getOrderCD.firstName }}</span> <span v-if="getOrderCD.lastName">{{ getOrderCD.lastName }}</span></li>
          <li v-if="getOrderCD.firstNameAdditionalContact"><span v-if="getOrderCD.firstNameAdditionalContact">{{ getOrderCD.firstNameAdditionalContact }}</span> <span v-if="getOrderCD.lastNameAdditionalContact">{{ getOrderCD.lastNameAdditionalContact }}</span></li>
          <li v-if="getOrderCD.mailingAddress.streetAddress">{{getOrderCD.mailingAddress.streetAddress}}<span v-if="getOrderCD.mailingAddress.unitNumber"></span></li>
          <li><span v-if="getOrderCD.mailingAddress.city">{{getOrderCD.mailingAddress.city}}</span>, <span v-if="getOrderCD.mailingAddress.state">{{getOrderCD.mailingAddress.state}}</span> 
            <span v-if="getOrderCD.mailingAddress.zipCode">{{getOrderCD.mailingAddress.zipCode}}</span></li>
          </ul>
        </div>
      </div>
      <div class="results wrap">
        <div v-if="getOrderData.internetSelections.isOrderingInternet">
          <h3>Internet Service</h3>
          <ul>
            <li>{{ getOrderData.internetSelections.isOrderingInternet ? 'Internet Service' : '' }} - {{ getOrderData.internetSelections.speedOptionID }}</li>
            <li>{{ getOrderData.internetSelections.installType }}</li>
            <li>{{ getOrderData.isUtopia ? 'Utopia Service Added' : '' }}</li>
            <li>{{ getOrderData.tvSelections.isOrderingTV ? 'TV Service Added' : '' }} - {{ getOrderData.tvSelections.packageID }}
              <ul>
                <li><span v-for="(ao, i) in getOrderData.tvSelections.addons" :key="i">{{ ao }}</span></li>
              </ul>
            </li>
          </ul>
        </div>
        <div v-if="getOrderData.phoneSelections.isOrderingPhone">
          <h3>Phone Service</h3>
          <ul>
            <li>{{ getOrderData.phoneSelections.isOrderingPhone ? 'Phone Service Added' : '' }}</li>
            <li v-if="getOrderData.phoneSelections.name">Installation Type: {{ getOrderData.phoneSelections.name }}
              <ul>
                <li><span v-for="(po, i) in getOrderData.phoneSelections.addons" :key="i">{{ po }}</span></li>
              </ul>
            </li>
            <li v-if="getOrderData.phoneNumberTransfer">Phone Number Transfer Request
              <ul>
                <li v-if="phoneNumberTransfer.numbersToTransfer">Numbers to Transfer: 
                  <span style="display: block;" v-for="(nms, n) in getOrderData.phoneNumberTransfer.numbersToTransfer" :key="n">{{ nms }}</span>
                </li>
                <li v-if="phoneNumberTransfer.currentServiceProvider">Current Provider: {{ phoneNumberTransfer.currentServiceProvider }}</li>
                <li v-if="phoneNumberTransfer.currentProviderAcctNum">Account Number: {{ phoneNumberTransfer.currentProviderAcctNum }}</li>
                <li v-if="phoneNumberTransfer.termsAccepted">Terms Accepted: {{ phoneNumberTransfer.termsAccepted ? 'Yes' : 'No' }}</li>
                <li v-if="phoneNumberTransfer.signature">{{ phoneNumberTransfer.signature }}</li>
                <li v-if="phoneNumberTransfer.signatureDate">{{ phoneNumberTransfer.signatureDate }}</li>
              </ul>
            </li>
          </ul>
        </div>
        <div style="padding-bottom: 20px;">
          <h3>Billing Preferences</h3>
          <ul>
            <li v-if="getBilling.isInit">{{ getBilling.isBeehiveBillElectronic ? 'Electronic Delivery' : 'Mail Delivery'}}</li>
            <li v-if="getOrderData.isUtopia && getBilling.utopiaDeliveryMethod">{{ getBilling.utopiaDeliveryMethod }}</li>
          </ul>
        </div>
      </div>
      <div class="wrap" style="margin-top: 20px;">
        <div class="nav">
          <button type="button" v-on:click="submitOrder">Submit Order</button>
        </div>
      </div>
    </div>
  </template>

  <script>
    export default {
      name: 'review',
      computed: {
        getOrderCD () {
          return this.$store.getters.getOrderCustomerData
        },
        getOrderData () {
          return this.$store.getters.getOrderData
        },
        getBilling () {
          return this.$store.getters.getOrderBilling
        }
      },
      methods: {
        submitOrder () {
          this.$store.dispatch('submitOrder')
        }
      }
    }
  </script>

  <style scoped lang="scss">
    * {
      box-sizing: border-box;
    }

    .centered {
      text-align: center;
    }

    .results {
      flex-direction: column !important;
      align-items: center;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }
    }

    .wrap {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      background-color: #f5f5f5;

      &.align-center {
        justify-content: center;
      }

      h3 {
        padding: 15px;
      }

      .inner {
        padding: 15px;

        label {
          display: block;
          margin-bottom: 15px;
        }
      }

      h3 {
        text-align: left;
      }

      form {
        display: flex;
        flex-flow: row wrap;

        label {
          padding: 10px 0 0;
          flex: 0 0 48%;

          span {
            display: block;
          }

          input {
            width: 90%;
            height: 40px;
          }

          &.full {
            flex: 0 0 100% !important;

            span {
              display: inline-block;
            }

            input {
              width: initial;
              height: initial;
            }
          }
        }
      }

      .col {
        flex-flow: column wrap;
      }
    }
  </style>
