<template>
  <div>
    <div class="results align-center">
      <div class="address-bar">
        <div class="wrap align-center">
          <p>Showing deals in <strong>{{getResults.address.streetAddress}}<span v-if="getResults.address.unitNumber"></span>, {{getResults.address.unitNumber}}
          {{getResults.address.city}}, {{getResults.address.state}} {{getResults.address.zipCode}}</strong></p>
        </div>
      </div>
    </div>
    <div class="wrap align-center page-head">
      <h2>Billing &amp; Account Information</h2>
    </div>
    <div class="wrap">
      <div class="inner">
        <h3>Account Holder Information</h3>
        <form>
          <label for="firstName"><span>First Name</span>
            <input type="text" id="firstName" v-model="customerInfo.firstName">
          </label>
          <label for="lastName"><span>Last Name</span>
            <input type="text" id="lastName" v-model="customerInfo.lastName">
          </label>
          <label for="primaryPhone"><span>Primary Phone</span>
            <input type="text" id="primaryPhone" v-model="customerInfo.primaryPhone">
          </label>
          <label for="secondaryPhone"><span>Secondary Phone</span>
            <input type="text" id="secondaryPhone" v-model="customerInfo.secondaryPhone">
          </label>
          <label for="emailAddress"><span>Email Address</span>
            <input type="text" id="emailAddress" v-model="customerInfo.emailAddress">
          </label>
          <label for="ssn"><span>Social Security Number</span>
            <input type="text" id="ssn" v-model="customerInfo.ssn">
          </label>
          <label for="dob"><span>Date of Birth</span>
            <input type="text" id="dob" v-model="customerInfo.dateOfBirth">
          </label>
          <label for="firstNameAdditionalContact"><span>Additonal Contact - First Name</span>
            <input type="text" id="firstNameAdditionalContact" v-model="customerInfo.firstNameAdditionalContact">
          </label>
          <label for="lastNameAdditionalContact"><span>Additonal Contact - Last Name</span>
            <input type="text" id="lastNameAdditionalContact" v-model="customerInfo.lastNameAdditionalContact">
          </label>
        </form>
      </div>
    </div>
    <div class="wrap">
      <div class="inner">
        <div class="split">
          <h3>Mailing Address</h3>
          <label for="same" class="full"><span>My billing address and mailing addresses are the same</span>
            <input type="checkbox" id="same" @change="updateAddress()">
          </label>
        </div>
        <form>
          <label for="address"><span>Address</span>
            <input type="text" id="address" v-model="customerInfo.mailingAddress.streetAddress">
          </label>
          <label for="unit"><span>Suite/Unit/Apartment</span>
            <input type="text" id="unit" v-model="customerInfo.mailingAddress.unitNumber">
          </label>
          <label for="City"><span>City</span>
            <input type="text" id="city" v-model="customerInfo.mailingAddress.city">
          </label>
          <label for="state"><span>State</span>
            <input type="text" id="state" v-model="customerInfo.mailingAddress.state">
          </label>
          <label for="zipCode"><span>ZIP Code</span>
            <input type="text" id="zipCode" v-model="customerInfo.mailingAddress.zipCode">
          </label>
        </form>
      </div>
    </div>
    <div class="wrap" v-if="!this.billingMailingSame">
      <div class="inner">
        <h3>Billing Address</h3>
        <form>
          <label for="b_address"><span>Address</span>
            <input type="text" id="b_address" v-model="customerInfo.billingAddress.streetAddress">
          </label>
          <label for="b_unit"><span>Suite/Unit/Apartment</span>
            <input type="text" id="b_unit" v-model="customerInfo.billingAddress.unitNumber">
          </label>
          <label for="b_city"><span>City</span>
            <input type="text" id="b_city" v-model="customerInfo.billingAddress.city">
          </label>
          <label for="b_state"><span>State</span>
            <input type="text" id="b_state" v-model="customerInfo.billingAddress.state">
          </label>
          <label for="b_zipCode"><span>ZIP Code</span>
            <input type="text" id="b_zipCode" v-model="customerInfo.billingAddress.zipCode">
          </label>
        </form>
      </div>
    </div>
    <div class="wrap align-center" style="margin-top: 20px;">
      <h3 class="h3-alt">Billing Preferences</h3>
      <div class="sub-block">
        <div class="inner" v-for="(ub, i) in getUtopia.infrastructureOptions[0].billDeliveryOptions" :key="'m' + i">
          <span class="title" v-if="ub.description">{{ ub.description }}</span>
          <span class="terms" v-if="ub.monthlyCharge">${{ ub.monthlyCharge }}/month</span>
          <button type="button" v-on:click="addUtopiaMail(ub.optionID)">Select Option</button>
        </div>
      </div>
    </div>
    <div class="wrap" style="margin-top: 20px;">
      <div class="nav">
        <button type="button" v-on:click="stepThreeComplete">Go to Step 4</button>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  export default {
    name: 'customerInformation',
    data () {
      return {
        billingMailingSame: false,
        customerInfo: {
          firstName: "",
          lastName: "",
          firstNameAdditionalContact: "",
          lastNameAdditionalContact: "",
          mailingAddress: {
            zipCode: "",
            streetAddress: "",
            city: "",
            unitNumber: "",
            state: ""
          },
          utopiaAddressID: this.$store.state.availRes.utopiaData.addressID,
          billingAddress: {
            zipCode: "",
            streetAddress: "",
            city: "",
            unitNumber: "",
            state: ""
          },
          primaryPhone: "",
          secondaryPhone: "",
          emailAddress: "",
          ssn: "",
          dateOfBirth: ""
        },
        myBilling: {
          isBeehiveBillElectronic: false,
          isInit: false,
          utopiaDeliveryMethod: ''
        }
      }
    },
    computed: {
      ...mapState(['resp']),
      getResults () {
        return this.$store.getters.getAvailability
      },
      getInternet () {
        return this.getResults.internetService
      },
      getTv () {
        return this.getResults.tvService
      },
      getPhone () {
        return this.getResults.phoneService
      },
      getUtopia () {
        return this.getResults.utopiaData
      }
    },
    methods: {
      updateAddress () {
        this.billingMailingSame = !this.billingMailingSame

        if (this.billingMailingSame) {
          this.customerInfo.billingAddress.zipCode = this.customerInfo.mailingAddress.zipCode
          this.customerInfo.billingAddress.streetAddress = this.customerInfo.mailingAddress.streetAddress
          this.customerInfo.billingAddress.city = this.customerInfo.mailingAddress.city
          this.customerInfo.billingAddress.unitNumber = this.customerInfo.mailingAddress.unitNumber
          this.customerInfo.billingAddress.state = this.customerInfo.mailingAddress.state
          console.log('same')
        } else {
          console.log('diff')
        }
      },
      addUtopiaMail (delId) {
        if (delId == 5) {
          this.myBilling.isBeehiveBillElectronic = true
          this.myBilling.isInit = true
        } else {
          this.myBilling.isBeehiveBillElectronic = false
          this.myBilling.isInit = true
        }
        this.myBilling.utopiaDeliveryMethod = delId.toString()
      },
      stepThreeComplete () {
        this.$store.dispatch('stepThree', false)
        this.$store.dispatch('stepFour', true)
        this.$store.dispatch('saveCustomerData', this.customerInfo)
        this.$store.dispatch('saveCustomerBills', this.myBilling)
      }
    }
  }
</script>

<style scoped lang="scss">

  * {
    box-sizing: border-box;
  }

  .results {
    flex-direction: column !important;
    align-items: center;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  .page-head {
    margin-top: 80px !important;
    margin-bottom: 40px !important;

    h2 {
      color: #093B75;
      text-align: center;
      font-size: 32px;
    }
  }

  .wrap {
    max-width: 1200px;
    margin: 0 auto 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &.align-center {
      justify-content: center;
    }

    .inner {
      background-color: #F6F6F6;
      border-radius: 5px;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      h3 {
        color: #34b2e3;
        font-size: 1.5rem;
        font-weight: bold;
        padding: 0 0 0 30px;
        letter-spacing: 0.25px;
        width: 100%;
      }
    }

    .split {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      h3 {
        width: 50%;
      }

      label {
        padding-right: 30px;

        span {
          color: #34b2e3;
        }
      }
    }

    .nav {
      text-align: center;
      width: 100%;

      button {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='260.636' height='81.531' viewBox='0 0 260.636 81.531'%3E%3Cg transform='translate(-829.682 -1133.976)'%3E%3Cpath d='M595.552,1199.641H774.657l40.766,40.766-40.766,40.766H595.552l-40.766-40.766Z' transform='translate(274.895 -65.664)' fill='%23fec751'/%3E%3C/g%3E%3C/svg%3E%0A");
        color: #173962;
        transition: .3s;
        text-transform: uppercase;
        position: relative;
        font-family: 'Barlow Condensed', sans-serif;
        font-size: 24px;
        font-weight: 700;
        font-style: normal;
        min-height: 55px;
        width: 180px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 15px auto 0;
        cursor: pointer;
      }
    }


    &.align-center {
      justify-content: center;
    }

    .sub-block {
      padding: 15px;
      max-width: 1000px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-flow: row wrap;
      padding: 30px 30px 30px;
      margin: 0;
      background-color: #F0F0F0;

      .inner {
        background-color: #34b2e3;
        padding: 30px 0 15px;
        min-height: 280px;
        flex: 0 0 49%;
        border-radius: 0;
        margin-top: 1rem;
        margin-right: 15px;
        box-shadow: 7px 9px 7px 1px rgba(0, 0, 0, 0.16);

        &:last-child {
          margin-right: 0;
        }

        span {
          color: #fff;
          text-align: center;
          display: block;
          width: 100%;

          &.title {
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 0;
          }

          &.terms {
            font-weight: 200;
            font-size: 1.5rem;
          }
        }

        button {
          background-image: none;
          background-color: #fff;
          color: #34b2e3;
          letter-spacing: 1px;
          text-transform: none;
          border-radius: 5px;
          max-height: 55px;
          margin: 0 auto;
          padding: 0 30px;
          font-size: 16px;
          font-weight: 700;
          font-style: normal;
          border: none;
          cursor: pointer;
        }
      }
    }

    .h3-alt {
      background-color: #34b2e3;
      color: #fff;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      font-size: 1.5rem;
      padding: 15px;
      max-width: 1000px;
      width: 100%;
      margin: 0;
    }

    form {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      padding: 15px;

      label {
        padding: 10px 15px;
        flex: 0 0 50%;
        margin-bottom: 1rem;

        span {
          display: block;
          margin-bottom: 5px;
          font-weight: 200;
        }

        input {
          width: 100%;
          height: 40px;
          background-color: #E8E8E8;
          border: none;
        }

        &.full {
          flex: 0 0 100% !important;

          span {
            display: inline-block;
          }

          input {
            width: initial;
            height: initial;
          }
        }
      }
    }

    .col {
      flex-flow: column wrap;
    }
  }

</style>
