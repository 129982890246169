import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex, VueAxios, axios)

// axios.defaults.baseURL = 'http://api.beehive.net:8080//signup/getserviceavailability'
// let headers = { 'Content-Type': 'application/json', 'x-request-content': 'normal' }

// axios.defaults.baseURL = 'http://apidev.beehive.net:8080/signup/getserviceavailability'
// let headers = { 'Content-Type': 'application/json', 'x-request-content': 'noservice' }

axios.defaults.baseURL = 'https://virtserver.swaggerhub.com/mfrancisbeehive/BeehiveAPI/2.3.0/'
const headers = { 'Content-Type': 'application/json', 'X-API-KEY': '6W82wK1cRfF3exoEkc3d1plopKCySm' };

export default new Vuex.Store({
  state: {
    init: [],
    availRes: {},
    StepOne: false,
    StepTwo: false,
    StepThree: false,
    StepFour: false,
    StepFive: false,
    customerInfo: {
      firstName: "",
      lastName: "",
      firstNameAdditionalContact: "",
      lastNameAdditionalContact: "",
      mailingAddress: {
        zipCode: "",
        streetAddress: "",
        city: "",
        unitNumber: "",
        state: ""
      },
      utopiaAddressID: "",
      billingAddress: {
        zipCode: "",
        streetAddress: "",
        city: "",
        unitNumber: "",
        state: ""
      },
      primaryPhone: "",
      secondaryPhone: "",
      emailAddress: "",
      ssn: "",
      dateOfBirth: ""
    },
    serviceSelections: {
      isUtopia: false,
      schemeID: "",
      internetSelections: {
        isOrderingInternet: false,
        speedOptionID: "",
        addonIDs: [],
        installType: ""
      },
      phoneSelections: {
        isOrderingPhone: false,
        numOfLines: null,
        includeNumberInDirectories: false,
        addonIDs: [],
        installType: ""
      },
      tvSelections: {
        isOrderingTV: false,
        packageID: "",
        cloudDVRHoursOptionID: "",
        concurrentStreamsOptionID: "",
        addonIDs: []
      },
      promoCode: ""
    },
    billDelivery: {
      isBeehiveBillElectronic: false,
      utopiaDeliveryMethod: "",
      isInit: false
    },
    serviceAgreement: {
      signature: "",
      signatureDate: "",
      electronicConsentGiven: false,
      accessibilityQuestionChecked: false,
      accessibilityQuestionField: ""
    },
    phoneNumberTransfer: {
      numbersToTransfer: [],
      currentServiceProvider: "",
      currentProviderAcctNum: "",
      termsAccepted: false,
      signature: "",
      signatureDate: "",
      previousPhoneBill: ""
    },
    testSubmission: true
  },
  mutations: {
    formInitMut (state, payload) {
      state.StepOne = true
      state.init = payload
    },
    checkAvailabilityMut (state, payload) {
      state.availRes = payload
    },
    isSubmitted (state, newSubmitted) {
      state.StepOne = newSubmitted
      state.StepTwo = true
    },
    stepTwoMut (state, newPrd) {
      console.log(newPrd)
      state.StepTwo = newPrd
    },
    stepThreeMut (state, info) {
      state.StepThree = info
    },
    stepFourMut (state, info) {
      state.StepFour = info
    },
    stepFiveMut (state, info) {
      state.StepFive = info
    },
    addInternetMut (state, payload) {
      console.log(payload)
      state.serviceSelections.internetSelections = payload
    },
    addUtopiaMut (state, payload) {
      console.log(payload)
      state.serviceSelections.isUtopia = payload
    },
    addUtopiaBillMut (state, payload) {
      console.log(payload)
      state.billDelivery = payload
    },
    addTvMut (state, payload) {
      console.log(payload)
      state.serviceSelections.tvSelections = payload
    },
    addPhoneMut (state, payload) {
      console.log(payload)
      state.serviceSelections.phoneSelections = payload
    },
    addPhoneTransferMut (state, payload) {
      console.log(payload)
      state.phoneNumberTransfer = payload
    },
    saveCustomerDataMut  (state, payload) {
      console.log(payload)
      state.customerInfo = payload
    },
    saveCustomerBillsMut  (state, payload) {
      state.billDelivery = payload
    },
    saveAgreementMut  (state, payload) {
      state.serviceAgreement = payload
    }
  },
  actions: {
    formInit ({ commit }) {
      axios.get('/signup/initform', { headers })
      .then(result => {
        commit('formInitMut', result.data)
      })
      .catch(error => {
        throw new Error(`API ${error}`)
      })
    },
    checkAvailability ({ commit }) {
      axios.get('/signup/getserviceavailability', { headers })
      .then(result => {
        console.log(result)
        commit('isSubmitted', false)
        commit('checkAvailabilityMut', result.data)
      })
      .catch(error => {
        throw new Error(`API ${error}`)
      })
    },
    submitOrder ({ state }) {
      axios.post('/signup/newcustomer', { headers }, { 
        serviceSelections: state.serviceSelections,
        customerInfo: state.customerInfo,
        billDelivery: state.billDelivery,
        serviceAgreement: state.serviceAgreement,
        phoneNumberTransfer: state.phoneNumberTransfer,
      })
      .then(result => {
        console.log(result)
      })
      .catch(error => {
        throw new Error(`API ${error}`)
      })
    },
    addInternet ({commit}, payload) {
      commit('addInternetMut', payload)
    },
    addUtopia ({commit}, payload) {
      commit('addUtopiaMut', payload)
    },
    addUtopiaBill ({commit}, payload) {
      commit('addUtopiaBillMut', payload)
    },
    addTv ({commit}, payload) {
      commit('addTvMut', payload)
    },
    addPhone ({commit}, payload) {
      commit('addPhoneMut', payload)
    },
    addPhoneTransfer ({commit}, payload) {
      commit('addPhoneTransferMut', payload)
    },
    saveCustomerData  ({commit}, payload) {
      commit('saveCustomerDataMut', payload)
    },
    saveCustomerBills  ({commit}, payload) {
      commit('saveCustomerBillsMut', payload)
    },
    saveAgreement  ({commit}, payload) {
      commit('saveAgreementMut', payload)
    },
    stepTwo ({commit}, payload) {
      commit('stepTwoMut', payload)
    },
    stepThree ({commit}, payload) {
      commit('stepThreeMut', payload)
    },
    stepFour ({commit}, payload) {
      commit('stepFourMut', payload)
    },
    stepFive ({commit}, payload) {
      commit('stepFiveMut', payload)
    }
  },
  getters: {
    getAvailability (state) {
      return state.availRes
    },
    getStepOne (state) {
      return state.StepOne
    },
    getStepTwo (state) {
      return state.StepTwo
    },
    getStepThree (state) {
      return state.StepThree
    },
    getStepFour (state) {
      return state.StepFour
    },
    getStepFive (state) {
      return state.StepFive
    },
    getOrderCustomerData (state) {
      return state.customerInfo
    },
    getOrderBilling (state) {
      return state.billDelivery
    },
    getOrderData (state) {
      return state.serviceSelections
    },
  }
})
