<template>
  <div class="form-wrap">
    <h3>Enter your address and see what services are available to you</h3>
    <form @submit="checkAddr">
      <label for="streetAddress" class="half"><span>Address</span>
        <input type="text" id="streetAddress" required v-model="subReq.streetAddress">
      </label>
      <label for="unitNumber" class="half"><span>Unit/Suite/Apt</span>
        <input type="text" id="unitNumber" v-model="subReq.unitNumber">
      </label>
      <label for="city" class="half"><span>City</span>
        <input type="text" id="city" required v-model="subReq.city">
      </label>
      <label for="state" class="half"><span>State</span>
        <input type="text" id="state" required v-model="subReq.state">
      </label>
      <label for="zip" class="full"><span>Zip Code</span>
        <input type="text" id="zip" required v-model="subReq.zip">
      </label>
      <button type="submit">Go</button>
    </form>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'getAvailability',
    data () {
      return {
        subReq: {
          streetAddress: '',
          unitNumber: '',
          city: '',
          state: '',
          zip: ''
        }
      }
    },
    computed: {
      ...mapState(['resp'])
    },
    methods: {
      checkAddr (event) {
        event.preventDefault()
        this.$store.dispatch('checkAvailability',  this.subReq )
      }
    }
  }
</script>

<style scoped lang="scss">

  $dark: #093B75;
  $light: #F0F0F0;

  * {
    box-sizing: border-box;
  }

  .form-wrap {
    max-width: 1200px;
    margin: 0 auto;

    h3 {
      text-align: center;
      color: $dark;
      font-size: 20px;
      letter-spacing: 1px;

      @media screen and (min-width: 1024px) {
        font-size: 30px;
      }
    }

    form {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    label {
      padding-top: 15px;
      padding-bottom: 15px;

      &.half {
        flex: 0 0 100%;
        padding-left: 15px;
        padding-right: 15px;

        @media screen and (min-width: 1024px) {
          flex: 0 0 50%;
        }
      }

      &.full {
        flex: 0 0 100%;
        padding-left: 15px;
        padding-right: 15px;
      }

      span {
        font-size: 16px;
        display: block;
        text-transform: uppercase;
        padding: 0 0 5px;
      }

      input {
        width: 100%;
        padding: 10px;
        font-size: 18px;
        background-color: $light;
        border: none;
        border-radius: 4px;
      }
    }

    button {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='260.636' height='81.531' viewBox='0 0 260.636 81.531'%3E%3Cg transform='translate(-829.682 -1133.976)'%3E%3Cpath d='M595.552,1199.641H774.657l40.766,40.766-40.766,40.766H595.552l-40.766-40.766Z' transform='translate(274.895 -65.664)' fill='%23fec751'/%3E%3C/g%3E%3C/svg%3E%0A");
      color: #173962;
      transition: .3s;
      text-transform: uppercase;
      position: relative;
      font-family: 'Barlow Condensed', sans-serif;
      font-size: 24px;
      font-weight: 700;
      font-style: normal;
      min-height: 45px;
      width: 180px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border: none;
      background-color: transparent;
      padding: 0;
      margin: 15px auto 0;
      cursor: pointer;
    }
  }
</style>
